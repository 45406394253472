<template>
  <div>
    <canvas id="granim-canvas"></canvas>
  </div>
</template>
<script>
import Granim from 'granim'
export default {
  name: 'Granim',
  props: {
    value: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
  },
  data () {
    return {
      GObj: Object
    }
  },
  mounted () {
    this.GObj = new Granim({
      element: '#granim-canvas',
      name: 'granim',
      opacity: [1, 1],
      direction: 'top-bottom',
      defaultStateName: this.value,
      states: {
        'Thunderstorm': {
          gradients: [
            ['#0C0D2C', '#D8D1DD', '#B39DAA']
          ]
        },
        'Drizzle': {
          gradients: [
            ['#D0DDE4', '#CCD8DF', '#919DA9']
          ]
        },
        'Rain': {
          gradients: [
            ['#1E3259', '#CCD8DF', '#919DA9']
          ]
        },
        'Snow': {
          gradients: [
            ['#7F94B2', '#94B8EB', '#D9E8FC']
          ]
        },
        'Clear': {
          gradients: [
            ['#51A2FB', '#93D7FE', '#D9F4FF']
          ]
        },
        'Clouds': {
          gradients: [
            ['#6287B6', '#8EB9E0', '#ECECEC']
          ]
        },
        'Atmosphere': {
          gradients: [
            ['#E0E4E9', '#C7C9D0', '#78777C']
          ]
        },
        'Dark': {
          gradients: [
            ['#02050E', '#2C3952', '#5C6E89']
          ]
        }
      }
    })
  },
  watch: {
    value(val) {
      switch(val) {
      case 'Thunderstorm':
        this.GObj.changeState('Thunderstorm')
        break;
      case 'Drizzle':
        this.GObj.changeState('Drizzle')
        break;
      case 'Rain':
        this.GObj.changeState('Rain')
        break;
      case 'Snow':
        this.GObj.changeState('Snow')
        break;
      case 'Clear':
        this.GObj.changeState('Clear')
        break;
      case 'Clouds':
        this.GObj.changeState('Clouds')
        break;
      case 'Dark':
        this.GObj.changeState('Dark')
        break;
      default:
        this.GObj.changeState('Atmosphere')
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.buttons {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  button {
    margin: 0.5rem;
  }
}
#granim-canvas {
  z-index: -1;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>