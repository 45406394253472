<template>
  <div class="animation-wrapper">
    <lottie-player ref="house" class="lottie-component house" preserveAspectRatio="xMidYMax slice" src="objects/scene_house_split02.json" background="transparent" speed="1"></lottie-player>
    <lottie-player ref="houseToSun" class="lottie-component" preserveAspectRatio="xMidYMax slice" src="objects/scene_house_split03.json" background="transparent" speed="1"></lottie-player>
    <lottie-player ref="sunToFullScreen" class="lottie-component" preserveAspectRatio="xMidYMax slice" src="objects/scene_house_split04.json" background="transparent" speed="1"></lottie-player>
    <lottie-player ref="fullScreenToSun" class="lottie-component" preserveAspectRatio="xMidYMax slice" src="objects/scene_house_split07.json" background="transparent" speed="1"></lottie-player>
    <lottie-player ref="sunOut" class="lottie-component" preserveAspectRatio="xMidYMax slice" src="objects/scene_house_split08.json" background="transparent" speed="1"></lottie-player>
    <lottie-player ref="objects" class="object" :src="objectSrc" preserveAspectRatio="xMidYMax slice" background="transparent" speed="1" loop></lottie-player>
    <ObjectContent :order="order" />
  </div>
</template>
<script>
import "@lottiefiles/lottie-player";
import ObjectContent from './ObjectContent.vue'
import { CountUp } from 'countup.js'
import { gsap, Power0, Power1 } from 'gsap'
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
export default {
  name: 'Animation',
  data () {
    return {
      order: 1,
      options: {
        startVal: 0,
        decimalPlaces: 1,
        duration: 4,
      },
      counter: null,
    }
  },
  components: { ObjectContent },
  mounted() {
    const timeline = gsap.timeline({repeat: -1, repeatDelay: 1});
    timeline.set(this.sunToFullScreen, { display: 'none' })
    .set(this.fullScreenToSun, { display: 'block', scale: 1.2 })
    .set(this.fullScreenToSun, { scale: 1 }, '5')
    .call(this.animationFullScreenToSun, null, 5)
    .to('.school-meal', {
      ease: Power0.easeIn,
      opacity: 0,
      top: '0',
    }, '>')
    .set('.school-meal', {
      display: 'none',
    }, '<+=1')
    .to('.api-data', {
      ease: Power0.easeNone,
      css: {
        display: 'block',
      },
    }, '>')
    .to('.school-meal', {
      css: {
        top: '120%', 
      },
    }, '>')
    .call(this.animationCountUp, null, '>')
    .call(this.animationSunOut, null, '<+=4')
    .set(this.fullScreenToSun, { display: 'none' }, '>')
    .set(this.sunOut, { display: 'block' }, '>')
    .set('.current-data-header', {
      ease: Power0.easeNone,
      css: {
        top: '20%',
      },
    }, '<+=1.5')
    .call(this.animationHouse, null, '<+=3')
    .set(this.sunOut, { display: 'none' }, '>')
    .to(this.house, 1, { 
      ease: Power1.easeNone,
      css: {
        display: 'block',
        top: '50%',
      },
    }, '<-=3')
    .to('.api-data-text', {
      ease: Power0.easeNone,
      css: {
        display: 'block',
      },
    }, '>')
    .call(this.animationHouseToSun, null, '<+=12')
    .set(this.house, { display: 'none' }, '>')
    .set(this.houseToSun, { display: 'block' }, '>')
    .to('.api-data-text, .api-data', {
      ease: Power0.easeNone,
      css: {
        display: 'none',
      },
    }, '>')
    .call(this.animationObject, null, '<+=2')

    .to('.sun-text', {
      ease: Power0.easeNone,
      opacity: 1,
    })
    .to(this.object, {
      ease: Power0.easeNone,
      opacity: 1,
    })
    .set('.sun-text', {
      display: 'flex',
    })
     .set(this.object, {
      display: 'block',
    })
    .call(this.animationSunToFullScreen, null, '<+=15')
    .set(this.houseToSun, { display: 'none' }, '>')
    .set(this.sunToFullScreen, { display: 'block' }, '>')
    .to('.sun-text', {
      ease: Power0.easeNone,
      opacity: 0,
    })
    .to(this.object, {
      ease: Power0.easeNone,
      opacity: 0,
    })
    .set('.sun-text', {
      display: 'none',
    })
     .set(this.object, {
      display: 'none',
    })
    .to('.school-meal', {
      display: 'block',
      top: '50%',
    }, '>')
    .call(this.newOrder, null, '<+=1');
  },
  computed: {
    fullScreenToSun() {
      return this.$refs.fullScreenToSun;
    },
    sunOut() {
      return this.$refs.sunOut;
    },
    house() {
      return this.$refs.house;
    },
    houseToSun() {
      return this.$refs.houseToSun;
    },
    object() {
      return this.$refs.objects;
    },
    sunToFullScreen() {
      return this.$refs.sunToFullScreen;
    },
    objectSrc() {
      switch(this.order) {
        case 1:
          return 'objects/scene_food.json';
        case 2:
          return 'objects/scene_car.json';
        case 3:
          return 'objects/scene_scooter.json';
        default:
          return 'objects/scene_gaming.json';
      }
    },
    getTag1() {
      return this.$store.state.tag1
    },
    nowApiData() {
      return this.$store.state.solvisDataCurrent[this.getTag1]?.Value && this.$store.state.solvisDataCurrent[this.getTag1].Value
    },
  },
  methods: {
    animationFullScreenToSun() {
      this.sunToFullScreen.stop();
      this.fullScreenToSun.play();
    },
    animationCountUp() {
      this.counter = new CountUp('apiData', this.nowApiData, this.options);
      this.counter.start()
    },
    animationSunOut() {
      this.fullScreenToSun.stop();
      this.sunOut.play()
    },
    animationHouse() {
      this.sunOut.stop();
      this.house.play()
    },
    animationHouseToSun() {
      this.house.stop();
      this.houseToSun.play()
    },
    animationObject() {
      this.object.load(this.objectSrc)
      this.object.play()
    },
    animationSunToFullScreen() {
      this.counter.reset()
      this.sunToFullScreen.play();
      this.object.stop();
      this.houseToSun.stop();
    },
    newOrder() {
      if (this.order === 4) this.order = 1
        else this.order++
    },
  },
}
</script>
<style lang="scss" global>
.animation-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  z-index: 30;
}

.text {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5.5rem 2.5rem 7.5rem;
  position: absolute;
  display: none;
  z-index: 40;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.object {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  height: 394px;
  width: 700px;
}

.lottie-component,
.object {
  display: none;
}

.lottie-component {
  width: 100%;
  height: 100%;
}

.house {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>