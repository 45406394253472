import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

var pageurl = window.parent.location.href.replace("?","&").toLowerCase();
var params = pageurl.split("&");
let schoolId = null;
params.forEach(e => {
  if(e.indexOf("id") == 0) {
    var ut = e.split("=");
    schoolId = ut[1]
  }
});

export default new Vuex.Store({
  state: {
    schoolId: null,
    foodId: null,
    tag: null,
    tag1: null,
    token: null,
    solvisData: {},
    solvisDataCurrent: {},
    schoolName: null,
    schoolMeal: {},
    weather: {},
    sun: {},
    latitude: 57.74414,
    longitude: 11.91806,
    errorPage: false,
  },
  actions: {
    async getData({ state }, token) {
      try {
        const response = await axios.get('https://lf.webport.se/api/v1/tag/read', {
          crossdomain: true,
          params: {
            tag: state.tag,
            token: token,
          },
        });
        if (response) {
          state.solvisData = response.data;
          state.errorPage = false;
        }
      } catch (error) {
        state.errorPage = true;
        console.error('error', error);
      }
    },
    async getCurrentData({ state }, token) {
      try {
        const response = await axios.get('https://lf.webport.se/api/v1/tag/read', {
          crossdomain: true,
          params: {
            tag: state.tag1,
            token: token,
          },
        });

        if (response) {
          state.solvisDataCurrent = response.data;
          state.errorPage = false;
        }
      } catch (error) {
        state.errorPage = true;
        console.error('error', error);
      }
    },
    async getSchoolMeal({ state }) {
      try {
        // districts/?province=5675684525506560
        // schools/?district=307867002 (Sydväst grundskolor - 307867002) (Hisingen grundskolor - 295387002)
        // menu/?school=5780245168783360 (Hultskolan - 5780245168783360) (Kärrdalsskolan - 6007621811175424)
        // Alla skolors id (t ex. schools/?district=1406004)
        const response = await axios.get(`//skolmaten.se/api/3/menu/?school=${state.foodId}`, {
          crossdomain: true,
          params: {
            clientVersion: 'strkseruajz71uh1igee',
            client: 'ycwjm4r3f7dvxgow3uzl',
          },
        });
        const meals = response.data.weeks.map(x => 
          x.days
        );
        return state.schoolMeal = meals[0]
      } catch (error) {
        console.error('error', error);
      }
    },
    async getPanelData({ dispatch, state }) {
      try {
        const response = await axios.get(`https://lf.webport.se/extlf/solarreport/getreportdata?id=${schoolId}&type=3`);
  
        if (schoolId && response) {
          state.schoolName = response.data.Description;
          state.tag = response.data.Tag4;
          state.tag1 = response.data.Tag1;
          state.latitude = response.data.Latitude || state.latitude;
          state.longitude = response.data.Longitude || state.longitude;
          state.foodId = response.data.SchoolMenuId;
          state.errorPage = false;

          if (state.latitude && state.longitude) dispatch('getWeather');
          if (state.tag && state.tag1) dispatch('checkStatus');
          if (state.foodId) dispatch('getSchoolMeal');
        } else {
          state.errorPage = true;
        }
      } catch (error) {
        console.error('error', error);
        dispatch('getWeather');
        state.errorPage = true;
      }
    },
    async checkStatus({ dispatch }) {
      try {
        const response = await axios.post('https://lf.webport.se/api/v1/access/extend');
        if (response) {
          dispatch('getData', response.data.Token)
          dispatch('getCurrentData', response.data.Token)
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch('getToken')
        }
      }
    }, 
    async getToken({ dispatch }) {
      try {
        const response = await axios.get('https://lf.webport.se/api/v1/access/token?username=boid&password=solvis');
        if (response) {
          dispatch('getData', response.data.Token)
          dispatch('getCurrentData', response.data.Token)
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async getWeather({ state }) {
      try {
        const response = await axios.get('//api.openweathermap.org/data/2.5/weather', {
          crossdomain: true,
          params: {
            appid: '3bef14dea86df2d5211b2dd5179ebeea',
            units: 'metric',
            lat: state.latitude,
            lon: state.longitude,
          },
        });
        state.weather = response.data.weather[0]
        state.sun = response.data;
        return [state.weather, state.sun]
      } catch (error) {
        console.error('error', error);
      }
    },
  }
})