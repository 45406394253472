<template>
  <let-it-snow
    v-bind="weather"
    :show="show"    
  ></let-it-snow>
</template>
<script>
export default {
  name: 'app',
  data() {
    return {
      weather: {},
      snow: {
        windPower: 3, // +/- direction
        speed: 2, // 0-5
        count: 60,
        size: 1, // 0-10+
        opacity: 1,
        images: [
          require('@/assets/images/weather/snow-04.png'),
          require('@/assets/images/weather/snow-05.png'),
          require('@/assets/images/weather/snow-06.png')
        ],
      },
      rain: {
        windPower: 0,  
        speed: 5,
        count: 40,
        size: 10,
        opacity: 1,
        images: [
          require('@/assets/images/weather/rain-01.png'),
          require('@/assets/images/weather/rain-02.png'),
          require('@/assets/images/weather/rain-03.png')
        ],
      },
      show: false      
    }
  },
  props: {
    currentWeather: {
      type: String,
      required: true,
      default() {
        return ''
      },
    },
  },
  mounted () {
    if (this.currentWeather) {
      this.show = true
      this.setCurrentWeather(this.currentWeather)
    } else {
      this.show = false
    }
  },
  methods: {
    setCurrentWeather(val) {
      switch(val) {
        case 'Rain':
          this.weather = this.rain
          break;
        case 'Snow':
          this.weather = this.snow
          break;
        default:
          this.weather = {}
      }
    }
  },
  watch: {
    currentWeather(val) {
      this.setCurrentWeather(val)
    }
  }
}
</script>
<style scoped>
.snow-wrap {
  z-index: 10 !important;
}
</style>
