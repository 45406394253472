<template>
  <div id="app" :class="{ 'dark-mode' : isDarkMode }">
    <div v-if="!errorPage" class="solvis-container">
      <header v-if="apiMeal.length > 0" class="header school-meal lunch-header">
        <div class="header__title">
          <p>Dagens lunch <span v-if="getSchoolName">hos {{ getSchoolName }}</span> lagad på solenergi</p>
        </div>
        <div class="meals">
          <div v-for="(meal, index) in apiMeal" :key="index" class="meals__content">
            <p class="large">{{meal}}</p>
          </div>
        </div>
      </header>
      <header v-else class="header school-meal no-lunch-header">
        <div>
          <p class="large">Vi lagar mat på solenergi</p>
          <lottie-player
            class="no-lunch-header__animation"
            src="objects/scene_food_404.json"
            preserveAspectRatio="xMidYMax slice"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
      </header>
      <div class="header current-data-header">
        <div class="header__title">
          <p class="api-data-text">Just nu producerar vi</p>
          <p id="apiData" class="xtra-large api-data"></p>
          <p class="api-data-text">kilowatt</p>
        </div>
      </div>
      <Animation />
      <div class="solvis-footer flex">
        <p>{{ datenow }}</p>
        <p>{{ timenow }}</p>
      </div>
    </div>
    <div v-else>
      <header class="header">
        <p class="large">Solvis kan inte visas just nu</p>
        <p>kom tillbaka om en stund</p>
      </header>
      <lottie-player class="animation404" src="objects/scene_404.json" preserveAspectRatio="xMidYMax slice" :style="errorPage && 'display: block'" background="transparent" speed="1" loop autoplay></lottie-player>
    </div>
    <WeatherEffects v-if="weatherData === 'Snow' || weatherData === 'Rain'" :current-weather="weatherData" />
    <Granim v-if="weatherData" :value="weatherData" />
    <Clouds v-if="weatherData === 'Clouds'" />
  </div>
</template>
<script>
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import Vue from 'vue'
import 'buefy/dist/buefy.css'
import Granim from './components/Granim.vue'
import Clouds from './components/Clouds.vue'
import Animation from './components/Animation.vue'
import LetItSnow from 'vue-let-it-snow'
import WeatherEffects from './components/WeatherEffects.vue'

Vue.use(LetItSnow)

export default {
  name: 'App',
  data () {
    return {
      apiTimer: '',
      timer: '',
      dateInterval: '',
      datenow: null,
      timenow: null,
    }
  },
  components: { Granim, Animation, Clouds, WeatherEffects },
  created() {
    this.getApiCalls()
    this.getCurrentTime()
    this.getDate()
    this.getWeather()

    this.apiTimer = setInterval(() => {
      this.getApiCalls()
    }, 60000) // varje minut

    this.timer = setInterval(() => {
      this.getDate()
      this.getWeather()
    }, 15 * 60 * 1000) // var 15 min

    this.dateInterval = setInterval(() => {
      this.getCurrentTime()
    }, 1000)
  },
  computed: {
    getSchoolName() {
      return this.$store.state.schoolName;
    },
    errorPage() {
      return this.$store.state.errorPage;
    },
    nowApiData() {
      return this.$store.state.solvisDataCurrent[this.getTag1]?.Value && this.$store.state.solvisDataCurrent[this.getTag1].Value
    },
    apiMeal() {
      const today = new Date();
      const weekday = new Array(6, 0, 1, 2, 3, 4, 5);
      const meals = this.$store.state.schoolMeal[weekday[today.getDay()]]
      return meals?.items.slice(0, 2) && meals.items.slice(0, 2) || []
    },
    getTag1() {
      return this.$store.state.tag1
    },
    weatherData() {
      const now = new Date()
      if (now > this.sunSet) return 'Dark'
        else return this.$store.state.weather.main
    },
    sunSet() {
      const now = new Date()
      const getSunset = this.$store.state.sun?.sys?.sunset * 1000 || now.getTime()
      const sunset = new Date(getSunset)
      return sunset
    },
    isDarkMode() {
      if (new Date() > this.sunSet || this.$store.state.weather.main === 'Rain' || this.$store.state.weather.main === 'Thunderstorm') return true
        else return false
    },
  },
  methods: {
    getApiCalls() {
      this.$store.dispatch('getPanelData')
    },
    getWeather() {
      this.$store.dispatch('getWeather')
    },
    getDate() {
      const today = new Date()
      this.datenow = format((today), 'EEEE, d MMMM', { locale: sv })
    },
    getCurrentTime() {
      const today = new Date()
      this.timenow = format((today), 'HH:mm', {})
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.dateInterval)
  }
}
</script>
<style lang="scss" global>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  height: 100vh;
  color: #000;
}

.dark-mode {
  color: #fff;

  .sun-data__sunset,
  .sun-data__sunrise,
  .lunch-header {
    color: #000;
  }
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: auto;
}

.solvis-container {
  z-index: 20;
}

.header {
  max-width: 1700px;
  padding: 5.5rem 2.5rem;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 18%;
  transform: translate(-50%, -50%);
  z-index: 40;

  &__title--larger {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.api-data,
.api-data-text {
  display: none;
}

.current-data-header {
  top: 48%;
  transition: all 0.8s ease;
}

.lunch-header {
  top: 50%;
  transition: all 1s;
}

.no-lunch-header {
  top: 50%;

  &__animation {
    height: 394px;
    margin: 0 auto;
    width: 700px;
  }
}

.meals {
  margin-top: 1.5rem;
  font-weight: bold;

  &__content {
    margin: 1rem;
  }
}

.solvis-footer {
  position: absolute;
  top: 0;
  padding: 2.5rem;
  align-items: center;
  z-index: 30;
  width: 100%;

  p {
    text-transform: capitalize;
    font-weight: bold;
  }
}

.xtra-large {
  font-size: 8rem;
  line-height: 1.1;
  font-weight: bold;
}

.large {
  font-size: 3.4rem;
  line-height: 1.2;
  font-weight: bold;
}

p {
  font-size: 2.2rem;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.weather {
  align-items: center;
  position: relative;
}

.animation404 {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 30;
}
</style>
