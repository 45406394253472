<template>
  <div>
    <div ref="sunText" class="sun-text text">
      <span v-if="apiData">
        <p>Idag har vi samlat ihop</p>
        <p class="xtra-large">{{ apiData }}</p>
        <p>kilowattimmar elektricitet från solen</p>
      </span>
      <span>
        <p>{{ objectText }}</p>
      </span>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
import { CustomEase } from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);
export default {
  name: 'Sun',
  props: {
    order: {
      type: Number,
      required: true,
      default() {
        return 1
      },
    }
  },
  computed: {
    apiData() {
      return this.$store.state.solvisData[this.getTag4]?.Value && this.$store.state.solvisData[this.getTag4].Value
    },
    getTag4() {
      return this.$store.state.tag
    },
    objectText() {
      let kwh = this.apiData;
      let item = null;
      let km = null;
      let scooters = null;
      let gaming = null;
      let itemCount = null;
      const distanceToSkara = 140;
      const distanceToBoras = 65;
      const distanceToLerum = 22;
      const distanceToVallhalla = 2;

      switch(this.order) {
        case 1:
          kwh = Math.round(kwh);
          if (kwh > 1) item = 'portioner'
            else item = 'portion'
          return `Av det kan skolköket laga ${kwh} ${item} mat`;
        case 2: {
          km = kwh / 0.201; // Medelförbrukning per km
          
          const destinationDistance = (destination, distance) => {
            item = km > distance * 2 ? 'elbilar' : 'elbil'
            itemCount = Math.round(km / distance);
            return `Med det kan ${itemCount} ${item} åka till ${destination}`;
          };

          if (km >= distanceToSkara) {
            return destinationDistance('Skara Sommarland', distanceToSkara)
          } else if (km >= distanceToBoras) {
            return destinationDistance('Borås Djurpark', distanceToBoras)
          } else if (km >= distanceToLerum) {
            return destinationDistance('Vattenpalatset i Lerum', distanceToLerum)
          } else {
            return destinationDistance('Vallhallabadet', distanceToVallhalla)
          }
        }
        case 3:
          scooters = Math.round(kwh / 0.5) // Ett barn åker skooter i 1h, förbrukning 0,5 kw/h, maxhastighet 20km/h
          item = scooters > 1 ? 'personer' : 'person'
          return `På det kan ${scooters} ${item} åka scooter i 1 timma`;
        default:
          gaming = Math.round(kwh / 0.45) // Snitt spelande, timma 0,15 kw, 3 timmar
          item = gaming > 1 ? 'personer' : 'person'
          return `På det kan ${gaming} ${item} spela tv-spel i 3 timmar`;
      }
    }
  },
}
</script>
